mtl.alloy.factory['faq-accordion'] = (function($) {
    'use strict';

	return function(element, options) {
		$(document).ready(function() {
			$('.faq').click( function(){
				accordionToggle( $(this) );	
			});

			function accordionToggle( element ){
				if( $(element).hasClass( 'active') ){
					$(element).removeClass('active');
					$('.answer', element).slideUp(200, 'linear');
				}
				else{
					$(element).addClass('active');
					$('.answer', element).slideDown(200, 'linear');		
				}
			}

		});

	};
}(jQuery));