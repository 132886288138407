mtl.alloy.factory['hero-slider'] = (function($) {
    'use strict';

	return function(element, options) {
		$(document).ready(function() {

			var nextArrow = $('.nav-arrow.next', element);
			var prevArrow = $('.nav-arrow.prev', element);

			var hero = $('.slider', element).slick({
				autoplay: false,
				slidesToShow: 1,
				slidesToScroll: 1,
				draggable: false,
				swipe: false,
				arrows: false
			});

			var nav = $('.overlay-slider', element).slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				fade: true,
				arrows: true,
				asNavFor: hero,
				nextArrow: nextArrow,
				prevArrow: prevArrow,
				autoplay: true,
				speed:500,
				autoplaySpeed: 10000,
			});
		});

	};
}(jQuery));