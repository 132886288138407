window.util = window.util || {};
window.util.breakpoint = (function($, element) {
	'use strict';

	/**
	 * Get a breakpoint reporter.
	 */
	function getBreakpointReporter(element) {

		var $element = $(element).first();
		element = $element[0];

		var listeners = [];
		var breakpoint = '';

		var self = null;
		var eventEmitter = null;


		/**
		 * Get the current breakpoint.
		 */
		function getBreakpoint() {

			return breakpoint || (breakpoint = queryBreakpoint());

		}


		/**
		 * Query the DOM for the current breakpoint.
		 */
		function queryBreakpoint() {

			return $element
				.css('font-family')
				.replace(/[\s'"]+/g, '')
				.split(',')[1] || ''
			;

		}


		/**
		 * Test the current breakpoint.
		 */
		function breakpointIs(test) {

			var states = ($.isArray(test)) ? test : (test + '').split(/\s+/);
			return (states.indexOf(getBreakpoint()) > -1);

		}


		/**
		 * Test the current breakpoint.
		 */
		function breakpointIsNot(test) {

			return !breakpointIs(test);

		}


		/**
		 * Notify an array of listeners that the breakpoint has changed.
		 */
		function notifyListeners(listeners, now, was) {

			var i, ix, fn;

			var leaveHandlers = [];
			var enterHandlers = [];

			for (i = 0, ix = listeners.length; i < ix; i++) {

				var listener = listeners[i];

				var didLeave = (listener.states.indexOf(was) > -1);
				var didEnter = (listener.states.indexOf(now) > -1);

				if (listener.leave && didLeave && !didEnter) {
					leaveHandlers.push(listener.leave);
				}

				if (listener.enter && didEnter && !didLeave) {
					enterHandlers.push(listener.enter);
				}

			}

			for (i = 0, ix = leaveHandlers.length; i < ix; i++) {
				fn = leaveHandlers[i];
				fn(now, was);
			}

			for (i = 0, ix = enterHandlers.length; i < ix; i++) {
				fn = enterHandlers[i];
				fn(now, was);
			}

		}


		/**
		 * Add a breakpoint listener.
		 */
		function addListener(states, enter, leave) {

			var listener = {
				states:  ($.isArray(states)) ? states.slice(0) : (states + '').split(/\s+/),
				enter:   enter,
				leave:   leave
			};

			listeners.push(listener);

			notifyListeners([listener], getBreakpoint(), '');

		}


		/**
		 * Notify listeners that the breakpoint has changed.
		 */
		function breakpointDidChange(now, was) {

			eventEmitter.trigger('change', [now, was]);
			notifyListeners(listeners, now, was);

		}


		/**
		 * Recalculate the current breakpoint.
		 */
		function onResize() {

			var was = getBreakpoint();
			var now = (breakpoint = queryBreakpoint());

			if (was !== now) {
				breakpointDidChange(now, was);
			}

		}


		// Listen to window.resize events
		$(window).on('resize orientationchange', onResize);

		// Define public API
		self = {
			get:  getBreakpoint,
			is:   breakpointIs,
			not:  breakpointIsNot,
			when: addListener,
			on:   null,
			off:  null,
		};

		eventEmitter = $(self);

		self.on = eventEmitter.on.bind(eventEmitter);
		self.off = eventEmitter.off.bind(eventEmitter);

		// Add a listener that visually highlights the reporter whenever the breakpoint changes
		self.on('change', function() {
			$(element).stop(true, false)
				.queue(function(next) {
					$(element).addClass('did-change');
					next();
				})
				.delay(400)
				.queue(function(next) {
					$(element).removeClass('did-change');
					next();
				});
		});

		// Return public API
		return self;

	}


	// Return a breakpoint reporter instance
	return getBreakpointReporter(element);

})(jQuery, document.getElementById('breakpoint-reporter'));
