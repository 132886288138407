;(function ($, alloy) {
    'use strict';

    /**
     * Finds the root parent of the given node.
     */
    function findRootNode(node) {

        var parentNode;

        while ((parentNode = node.parentNode)) {
            node = parentNode;
        }

        return node;

    }


    /**
     * Confirms that the modal is allowed to open.
     */
    function confirmModalOpening(initialEvent, then) {

        // Attach a listener to the root event...
        $(findRootNode(initialEvent.currentTarget)).one(initialEvent.type, function (finalEvent) {

            // Exit if the final event does not match the initial event
            if (initialEvent.originalEvent !== finalEvent.originalEvent) {
                return;
            }

            // Exit if the original click event was prevented
            if (finalEvent.isDefaultPrevented()) {
                return;
            }

            // Emit a modal.opener event on the original target
            var modalEvent = $.Event('modal.opener');
            $(initialEvent.currentTarget).trigger(modalEvent);

            // Exit if the modal.opener event was prevented
            if (modalEvent.isDefaultPrevented()) {
                return;
            }

            // Invoke the callback
            finalEvent.preventDefault();
            then(finalEvent);

        });

    }


    /**
     * Handles click events on modal-opening anchors.
     */
    function onClickAnchor(event) {

        var anchor = event.currentTarget;

        var href = anchor.getAttribute('href');
        if (!href) {
            return;
        }

        confirmModalOpening(event, function () {

            app.modal.open(href, {
                type: 'ajax'
            });

        });

    }


    /**
     * Handles submit events on modal-opening forms.
     */
    function onSubmitForm(event) {

        confirmModalOpening(event, function () {

            var form = event.currentTarget;

            app.modal.open(form.action, {
                type: 'ajax',
                ajax: {
                    type: (form.method || 'GET').toUpperCase(),
                    data: $(form).serialize()
                }
            });

        });

    }


    /**
     * Handles clicks on modal-opening form submit buttons.
     */
    function onClickSubmit(event) {

        // Buttons aren't considered as 'successful' elements when a form is submitted programmatically, which
        // means that they aren't included in the output jQuery's `serialize` function.

        // To get around this, we listen for click events on named buttons and temporarily attach a hidden input
        // with the same name and value.

        var button = event.currentTarget;
        if (!button.name) {
            return;
        }

        var input = util.parseHTML('<input type="hidden" />')[0];
        input.name = button.name;
        input.value = button.value;
        $(input).insertAfter(button);

        setTimeout(function () {
            $(input).remove();
        }, 0);

    }


    /**
     * Handles click events on modal-closing buttons.
     */
    function onClickClose(event) {

        event.preventDefault();

        app.modal.close();

        // Scroll to link destination after modal close
        if(event.target.tagName.toLowerCase() === 'a') {
          var href = event.target.href;

          if (href.indexOf("#") !== -1) {
            var $destination = $(href.substring(href.indexOf("#")));

            $('html, body').animate({
              scrollTop: $destination.offset().top
            }, 500);
          }
        }
    }


    /**
     * Decorates an event listener to only trigger when the target element is inside a modal.
     */
    function whenInsideModal(fn) {

        return function () {

            if (!app.modal.contains(event.currentTarget)) {
                return;
            }

            var argv = [];
            for (var i = 0, ix = arguments.length; i < ix; i++) {
                argv[i] = arguments[i];
            }

            fn.apply(this, argv);

        };

    }


    /**
     * Initialises modal-opening behaviour on the given element.
     */
    function modalOpener(element, options) {

        var $element = $(element);

        if ($element.is('a')) {
            $element.on('click', onClickAnchor);
        }

        else if ($element.is('form')) {
            $element.on('submit', onSubmitForm);
            $element.on('click', 'button[type="submit"]', onClickSubmit);
        }

        else {
            console.error('Can not initialise modal-opening behaviour on <' + element.tagName + '> element');
        }

    }


    /**
     * Initialises modal-reusing behaviour on the given element.
     */
    function modalReuser(element, options) {

        var $element = $(element);

        if ($element.is('a')) {
            $element.on('click', whenInsideModal(onClickAnchor));
        }

        else if ($element.is('form')) {
            $element.on('submit', whenInsideModal(onSubmitForm));
        }

        else {
            console.error('Can not initialise modal-reusing behaviour on <' + element.tagName + '> element');
        }

    }


    /**
     * Initialises modal-closing behaviour on the given element.
     */
    function modalCloser(element, options) {

        // Accepts the following options:
        //     immediate:
        //         If true, the modal will be closed immediately

        var $element = $(element);

        if ($element.is('a')) {
            $element.on('click', whenInsideModal(onClickClose));
        }

        else if ($element.is('form')) {
            $element.on('submit', whenInsideModal(onClickClose));
        }

        else {
            console.error('Can not initialise modal-closing behaviour on <' + element.tagName + '> element');
            return;
        }

        if (options && options.immediate) {
            app.modal.close();
        }

    }


    // Export public API
    alloy.modal = {
        opener: modalOpener,
        reuser: modalReuser,
        closer: modalCloser
    };

})(jQuery, mtl.alloy.factory);
