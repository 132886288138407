mtl.alloy.factory['products-filter'] = (function($) {
    'use strict';

	return function(element, options) {
		$(document).ready(function() {
			var $grid = $('.products .product-wrap', element);

			$('.filters .filter').click(function(){
				filter( $(this), $grid);
			});

			$('.filters .mobile-dropdown').change( function(){
				filter($('option:selected'), $grid)
			}).change();

			function filter( element, grid ){
				// if element clicked is already active
				if( $(element).hasClass('active') ){
					$(element).removeClass('active');

					//prevent multiple filters
					$( '.filter.active' ).removeClass('active');
						
					grid.isotope({ filter: '*' });
				}
				else{
					//prevent multiple filters
					$( '.filter.active' ).removeClass('active');
					
					$(element).addClass('active');

					var filterValue = $(element).attr('data-filter');


					if( filterValue == 'number' ){
						var capacityFilter = $(element).attr('data-capacity');

						grid.isotope({ filter: function(){
							var maxCapacity = $(this).attr('data-max-capacity');
							return Number(maxCapacity) >= Number(capacityFilter);
						} });
						
					}else{
						grid.isotope({ filter: filterValue });
					}
				}
			}

			//prevents items from overlapping on smaller screen sizes
			$grid.imagesLoaded( function(){
				$grid.isotope({
					itemSelector: '.product',
					layoutMode: 'fitRows',
					fitRows: {
						gutter: 20
					}
				});
			});
		});

	};
}(jQuery));