window.util = window.util || {};
window.util.scroll = (function($) {
	'use strict';

	var ELEMENT_TOP_BIAS = -80;

	var isBound = false;
	var isActive = false;

	var $window = $(window);
	var $document = $(document);

	var $scrollProxy = $({});


	/**
	 * Get the height of the window viewport.
	 */
	function getWindowHeight() {

		return $window.height();

	}


	/**
	 * Get the height of the document body.
	 */
	function getDocumentHeight() {

		return $document.height();

	}


	/**
	 * Get the current scroll position.
	 */
	function getWindowScrollTop() {

		return $window.scrollTop();

	}


	/**
	 * On scroll animate step.
	 */
	function onScrollStep(now) {

		if (!isNaN(now) || !isActive) {
			document.body.scrollTop = document.documentElement.scrollTop = +now;
		}

	}


	/**
	 * Scroll to the given offset.
	 */
	function toOffset(offset) {

		var min = 0;
		var max = getDocumentHeight() - getWindowHeight();

		offset = Math.max(min, Math.min(+offset, max));

		if (Math.abs(offset) < 1) {
			return;
		}

		var scrollTop = getWindowScrollTop();

		var distance = Math.abs(scrollTop - offset);
		var duration = 160 + Math.sqrt(distance * 1) * 20;

		isActive = true;

		$scrollProxy
			.stop(true, false)
			.prop('scrollTop', scrollTop)
			.animate({
				scrollTop:  offset,
			}, {
				duration:   duration,
				easing:     $.easie(0.3, 0, 0.3, 1),
				step:       onScrollStep,
			}, function() {
				isActive = false;
			})
		;

		bindAbortListener();

	}


	/**
	 * Scroll to the given element.
	 */
	function toElement(element) {

		var $element = $(element).first();
		if ($element[0] == null) {
			return;
		}

		toOffset(
			$element.offset().top + ELEMENT_TOP_BIAS
		);

	}


	/**
	 * Scroll by the given amount.
	 */
	function byAmount(amount) {

		toOffset(
			$(window).scrollTop() + (+amount)
		);

	}


	/**
	 * Stop scrolling.
	 */
	function stop() {

		if (!isActive) {
			return;
		}

		isActive = false;

		$scrollProxy.stop(true, false);

	}


	/**
	 * ...
	 */
	function bindAbortListener() {

		if (isBound) {
			return;
		}

		$(document.body).on('mousedown mousewheel pointerdown touchstart', stop);

		isBound = true;

	}


	// Public API
	return {
		toOffset:   toOffset,
		toElement:  toElement,
		byAmount:   byAmount,
		stop:       stop
	};

}(jQuery));
