mtl.alloy.factory['clients-slider'] = (function($) {
    'use strict';

	return function(element, options) {
		$(document).ready(function() {

			var nextArrow = $('.nav-arrow.next', element);
			var prevArrow = $('.nav-arrow.prev', element);

			$('.clients-slider', element).slick({
				slidesToShow: 8,
				slidesToScroll: 1,
				autoplay: false,
				speed:500,
				autoplaySpeed: 5000,
				variableWidth: true,
				nextArrow: nextArrow,
				prevArrow: prevArrow,
				infinite: true
			});
		});

	};
}(jQuery));