;(function() {
	'use strict';

	var util = window.util = (window.util || {});

	var TABLE_ELEMENTS = {
		th: 'tr',
		td: 'tr',
		tr: 'tbody',
		tbody: 'table',
		thead: 'table',
		tfoot: 'table',
	};

	var nodeTypeTest = /^[^<]*<([a-z]+)/i;

	var containerCache = {};


	/**
	 * Gets the default container.
	 */
	function getDefaultContainer() {

		var type = '*';

		if (containerCache[type] != null) {
			return containerCache[type];
		}

		return (containerCache[type] = document.createElement('div'));

	}


	/**
	 * Gets an appropriate container for the given table node type.
	 */
	function getTableContainer(type) {

		if (containerCache[type] != null) {
			return containerCache[type];
		}

		var markup = '';
		var outerType, innerType = type;
		while ((outerType = TABLE_ELEMENTS[innerType])) {
			markup = '<' + outerType + '>' + markup + '</' + outerType + '>';
			innerType = outerType;
		}

		var childNode, containerNode = (util.parseHTML(markup))[0];
		while ((childNode = containerNode.childNodes.item(0))) {
			containerNode = childNode;
		}

		return (containerCache[type] = containerNode);

	}


	/**
	 * Gets an appropriate container for the given content.
	 */
	function getContainer(value) {

		var match = nodeTypeTest.exec(value);

		var type = (match && match[1]) ?
			match[1].toLowerCase() :
			null;

		if (TABLE_ELEMENTS[type] != null) {
			return getTableContainer(type);
		}

		return getDefaultContainer();

	}


	/**
	 * Parses a string into an array of HTML nodes.
	 *
	 * This is intended to act as a replacement for jQuery.parseHTML, which seems to have trouble with embedded SVG.
	 */
	util.parseHTML = function (value) {

		value += '';

		var container = getContainer(value);
		container.innerHTML = value;

		var out = [];

		var nodeList = container.childNodes;
		for (var i = 0, ix = nodeList.length; i < ix; i++) {
			out[i] = nodeList.item(i);
		}

        for (i = 0; i < ix; i++) {
            container.removeChild(out[i]);
        }

		return out;

	};


	/**
	 * Parses a string into an array of HTML nodes.
	 *
	 * This function will only return content inside the <body> element.
	 */
	util.parseHTMLBody = function (content) {

		content = (function (content) {

			var open = content.indexOf('<body');
			if (open < 0) {
				return content;
			}

			var openEnd = content.indexOf('>', open);
			if (openEnd < 0) {
				return content;
			}

			var close = content.lastIndexOf('</body');
			if (close < 0) {
				return content;
			}

			return content.substring(openEnd + 1, close);

		})(content);

		return util.parseHTML(content);

	};

}());
