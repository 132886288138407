mtl.alloy.factory['lightboxGallery'] = (function($) {
    'use strict';

	return function(element, options) {
		$(document).ready(function() {
			var lightboxModal = $('#lightboxModal');

			$('.image', element).click( function(){
				$(lightboxModal).fadeIn("fast");

				$('body').css('overflow-y', 'hidden');

				var startSlide = $(this).attr('data-slider-index') - 1;

				var slider = $('.gallerySlider', lightboxModal).slick({
					slidesToShow: 1,
					slidesToScroll: 1,
					autoplay: false,
					speed:500,
					nextArrow: $('.modalSlidernav.next', lightboxModal),
					prevArrow: $('.modalSlidernav.prev', lightboxModal),
					centerMode: true,
					centerPadding: '0px',
					infinite: false,
					initialSlide: parseInt(startSlide)
				});

				$('.modalSliderExit', lightboxModal).click( function(){
					$(lightboxModal).fadeOut("fast", function(){
						$(slider).slick('unslick');
						$('body').css('overflow-y', 'scroll');
					});
				});
			});
		});
	};
}(jQuery));