mtl.alloy.factory['testimonial-slider'] = (function($) {
    'use strict';

	return function(element, options) {
		$(document).ready(function() {
			$(element).slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				autoplay: true,
				speed:500,
				autoplaySpeed: 5000,
				arrows: false,
				variableWidth: false
			});
		});

	};
}(jQuery));