mtl.alloy.factory['mobile-navigation'] = (function($) {
    'use strict';

	return function(element, options) {
		$(document).ready(function() {
			var mobileMenu = $('.mobile-menu', element);

			$('.mobile-menu-trigger', element).click( function(){
				$('.burger-icon .line').toggleClass('animate');
				
				$('.mobile-menu').toggleClass('active');

				if( $('.burger-icon .line').hasClass('animate') ){
					$(mobileMenu).slideDown();
				}
				else{
					$(mobileMenu).slideUp();
				}

			});
		});
	};
}(jQuery));