mtl.alloy.factory['occasion-slider'] = (function($) {
    'use strict';

	return function(element, options) {
		$(document).ready(function() {

			var nextArrow = $('.slidernav.next', element);
			var prevArrow = $('.slidernav.prev', element);

			$('.slider-wrap', element).slick({
				slidesToScroll: 1,
				autoplay: true,
				speed:900,
				autoplaySpeed: 10000,
				variableWidth: true,
				nextArrow: nextArrow,
				prevArrow: prevArrow,
				centerMode: true
			});
		});

	};
}(jQuery));