;(function ($) {
    'use strict';

    var ARIA_HIDEABLE_ELEMENTS = ['main', 'section', 'article', 'header', 'footer', 'div'];
    var FOCUSABLE_ELEMENTS = ['a[href]', 'area[href]', 'input:not([disabled])', 'select:not([disabled])', 'textarea:not([disabled])', 'button:not([disabled])', 'iframe', 'object', 'embed', '[contenteditable]', '[tabindex]:not([tabindex^="-"])'];
    var TAB_KEY = 9;
    var ESCAPE_KEY = 27;

    var defaultAjaxOptions = {
        cache: false
    };

    var app = window.app = (window.app || {});

    var isActive = false;
    var isLoading = false;

    var eventEmitter = null;

    var focusedBeforeDialog = null;
    var activeRequest = null;
    var blockCloseUntil = null;

    var $container = null;
    var $currentDialog = null;
    var $hiddenSiblings = null;


    /**
     * Tests whether the given element is inside a modal popup.
     */
    function isInsideModal(element) {

        return $(element).closest('.modal').length > 0;

    }


    /**
     * Gets an event instance.
     */
    function getEvent(type, props) {

        var event = $.Event(type);

        if (props != null) {
            $.extend(event, props);
        }

        return event;

    }


    /**
     * Triggers an event.
     */
    function triggerEvent(type, props) {

        var event = getEvent(type, props);

        eventEmitter.trigger(event);

        return event;

    }


    /**
     * Gets the modal container element.
     */
    function getContainer() {

        if (!$container) {

            var element = util.parseHTML(
                '<div class="modal is-hidden" aria-hidden="true">' +
                '<div class="modal__overlay"></div>' +
                '<div class="modal__loading is-hidden">' +
                '<div class="modal__loading-dot dot-1"></div>' +
                '<div class="modal__loading-dot dot-2"></div>' +
                '<div class="modal__loading-dot dot-3"></div>' +
                '</div>' +
                '</div>'
            )[0];

            $container = $(element)
                .on('click', '.modal__overlay, .modal__close', function () {
                    if (Date.now() > blockCloseUntil) {
                        close();
                    }
                })
                .appendTo(document.body)
            ;

        }

        return $container;

    }


    /**
     * Applies an entering animation to the given element.
     */
    function animateEnter($element, instant, then) {

        then = then || $.noop;

        $element.stop(true, false);

        if (instant !== true) {
            $element
                .toggleClass('is-entering', !$element.hasClass('is-leaving'))
                .removeClass('is-leaving is-hidden')
                .delay(33)
                .queue(function (next) {
                    $element.removeClass('is-entering');
                    then();
                    next();
                })
            ;
        }

        else {
            $element.removeClass('is-hidden is-leaving is-entering');
            then();
        }

    }


    /**
     * Applies a leaving animation to the given element.
     */
    function animateLeave($element, instant, then) {

        then = then || $.noop;

        $element.stop(true, false);

        if (instant !== true) {
            $element
                .removeClass('is-entering')
                .addClass('is-leaving')
                .delay(350)
                .queue(function (next) {
                    $element.addClass('is-hidden').removeClass('is-leaving');
                    then();
                    next();
                })
            ;
        }

        else {
            $element.removeClass('is-leaving is-entering').addClass('is-hidden');
            then();
        }

    }


    /**
     * Activates the modal overlay.
     */
    function activate(instant) {

        if (isActive) {
            return;
        }

        var $container = getContainer();

        var event = triggerEvent('beforeActivate');
        if (event.isDefaultPrevented()) {
            return;
        }

        isActive = true;

        // Keep a reference to the currently focused element to be able to restore
        // it later, then set the focus to the first focusable child of the dialog
        // element
        focusedBeforeDialog = document.activeElement;

        // Iterate over the targets to disable them by setting their `aria-hidden`
        // attribute to `true`; in case they already have this attribute, keep a
        // reference of their original value to be able to restore it later
        $hiddenSiblings = $container.siblings(ARIA_HIDEABLE_ELEMENTS.join(', ')).each(function (i, target) {

            var original = target.getAttribute('aria-hidden');

            if (original) {
                target.setAttribute('data-aria-hidden-was', original);
            }

            target.setAttribute('aria-hidden', 'true');

        });

        // Bind a focus event listener to the body element to make sure the focus
        // stays trapped inside the dialog while open, and start listening for some
        // specific key presses (TAB and ESC)
        document.body.addEventListener('focus', _maintainFocus, true);
        document.addEventListener('keydown', _bindKeypress);

        // Show the modal container
        animateEnter($container.removeAttr('aria-hidden'), instant, function () {
            triggerEvent('afterActivate');
        });

        $(document.documentElement).addClass('modal-active');

    }


    /**
     * Deactivates the modal overlay.
     */
    function deactivate(instant) {

        if (!isActive) {
            return;
        }

        var event = triggerEvent('beforeDeactivate');
        if (event.isDefaultPrevented()) {
            return;
        }

        isActive = false;

        activeRequest = null;

        hideLoading();
        removeCurrentDialog();

        $(document.documentElement).removeClass('modal-active');

        // Hide the modal container
        animateLeave(getContainer().attr('aria-hidden', 'true'), instant, function () {
            triggerEvent('afterDeactivate');
        });

        // Iterate over the targets to enable them by remove their `aria-hidden`
        // attribute or resetting them to their initial value
        $hiddenSiblings.each(function (i, target) {

            var original = target.getAttribute('data-aria-hidden-was');

            if (original) {
                target.setAttribute('aria-hidden', original);
                target.removeAttribute('data-aria-hidden-was');
            }
            else {
                target.removeAttribute('aria-hidden');
            }

        });

        // Bind a focus event listener to the body element to make sure the focus
        // stays trapped inside the dialog while open, and start listening for some
        // specific key presses (TAB and ESC)
        document.body.addEventListener('focus', _maintainFocus, true);
        document.addEventListener('keydown', _bindKeypress);

        // If there was a focused element before the dialog was opened, restore the
        // focus back to it
        if (focusedBeforeDialog && $(focusedBeforeDialog).closest('.layout-header__nav').length === 0) {
            focusedBeforeDialog.focus();
        }

    }


    /**
     * Shows a dialog element.
     */
    function showDialog($dialog, instant) {

        activate();
        hideLoading();
        removeCurrentDialog();

        activeRequest = null;

        var event = triggerEvent('beforeShow', {
            dialog: $dialog,
        });

        if (event.isDefaultPrevented()) {
            deactivate();
            return;
        }

        $dialog = event.dialog;

        $currentDialog = $dialog;
        getContainer().append($dialog);

        animateEnter($dialog, instant, function () {

            triggerEvent('afterShow', {
                dialog: $dialog,
            });

            setFocusToFirstItem($dialog[0]);

        });

    }


    /**
     * Removes a dialog element from the overlay.
     */
    function removeDialog($dialog, instant) {

        triggerEvent('beforeClose', {
            dialog: $dialog,
        });

        animateLeave($dialog, instant, function () {

            triggerEvent('beforeRemove', {
                dialog: $dialog,
            });

            $dialog.remove();

            triggerEvent('afterClose', {
                dialog: $dialog,
            });

        });

    }


    /**
     * Removes the currently-active dialog from the overlay.
     */
    function removeCurrentDialog(instant) {

        if (!$currentDialog) {
            return;
        }

        removeDialog($currentDialog, instant);
        $currentDialog = null;

    }


    /**
     * Creates a dialog element.
     */
    function createDialog(content) {

        var element = util.parseHTML(
            '<div class="modal__dialog is-hidden" role="dialog">' +
            '<div class="modal__body">' +
            '<div class="modal__document" role="document"></div>' +
            '<button class="modal__close" type="button" aria-label="Close this dialog"></button>' +
            '</div>' +
            '</div>'
        )[0];

        var $dialog = $(element);

        var $content = $(
            (typeof content === 'string') ? util.parseHTMLBody(content) : content
        );

        $dialog.find('.modal__document').append($content);
        $dialog.find('.modal__close').append(app.svgicon('modal/modal-close'));

        return $dialog;

    }


    /**
     * Tries to infer the type of the given content.
     */
    function inferContentType(content) {

        if (typeof content === 'string') {

            var fc = content.trim().substr(0, 1);
            if (fc !== '<') {
                return 'ajax';
            }

        }

        return 'inline';

    }


    /**
     * Opens a dialog by inserting the given inline content.
     */
    function openInline(content) {

        showDialog(createDialog(content));

    }


    /**
     * Opens a dialog by requesting content from the given URL.
     */
    function openAjax(url, options) {

        var beforeLoad = triggerEvent('beforeLoad', {
            url: url,
        });

        if (beforeLoad.isDefaultPrevented()) {
            deactivate();
            return;
        }

        url = beforeLoad.url;

        showLoading();

        var request = $.ajax(url, $.extend({}, defaultAjaxOptions, options.ajax || {}));

        activeRequest = request;

        // When the request is successful...
        request.always(function (xhr) {

            var content = xhr.responseText;

            if (request !== activeRequest || !isActive) {
                return;
            }

            var afterLoad = triggerEvent('afterLoad', {
                content: content
            });

            if (request !== activeRequest || !isActive) {
                return;
            }

            if (afterLoad.isDefaultPrevented()) {
                deactivate();
                return;
            }

            content = afterLoad.content;

            var $content;
            if (typeof afterLoad.content === 'string') {

                var parsed = util.parseHTMLBody(afterLoad.content);

                var afterParse = triggerEvent('afterParse', {
                    content: parsed,
                });

                if (request !== activeRequest || !isActive) {
                    return;
                }

                if (afterParse.isDefaultPrevented()) {
                    deactivate();
                    return;
                }

                $content = $(afterParse.content);

            }
            else {
                $content = $(content);
            }

            openInline($content);

        });

        // @todo Show an error when the request fails...

        // When the request is complete...
        request.always(function () {

            if (request !== activeRequest) {
                return;
            }

            activeRequest = null;

        });

    }


    /**
     * Opens a dialog with the given content.
     */
    function open(content, options) {

        // Accepts the following options:
        //     type:
        //         One of "inline", "ajax"
        //     ajax:
        //         Additional options to pass to AJAX requests
        //         See https://api.jquery.com/jQuery.ajax/

        options = options || {};

        var type = options.type || inferContentType(content);

        if (type === 'inline') {
            openInline(content);
        }

        else if (type === 'ajax') {
            openAjax(content, options);
        }

        else {
            console.error('Could not open modal with content type "' + type + '"');
            return;
        }

        blockCloseUntil = Date.now() + 500; // Used to prevent accidental closure on e.g. double-click

    }


    /**
     * Closes the overlay.
     */
    function close() {

        deactivate();

    }


    /**
     * Shows the loading animation.
     */
    function showLoading(instant) {

        if (isLoading) {
            return;
        }

        isLoading = true;

        activate();
        removeCurrentDialog();

        animateEnter(getContainer().find('.modal__loading'), instant);

    }


    /**
     * Hides the loading animation.
     */
    function hideLoading(instant) {

        if (!isLoading) {
            return;
        }

        isLoading = false;

        animateLeave(getContainer().find('.modal__loading'), instant);

    }


    // A11y dialog functions
    // ---------------------------------------------------------

    /**
     * Gets the focusable children of the given element.
     */
    function getFocusableChildren(node) {

        return $(FOCUSABLE_ELEMENTS.join(','), node).filter(function (i, child) {
            return !!(child.offsetWidth || child.offsetHeight || child.getClientRects().length);
        }).get();

    }


    /**
     * Set the focus to the first focusable child of the given element.
     */
    function setFocusToFirstItem(node) {

        var focusableChildren = getFocusableChildren(node);

        if (focusableChildren.length) {
            focusableChildren[0].focus();
        }

    }


    /**
     * Trap the focus inside the given element.
     */
    function trapTabKey(node, event) {

        var focusableChildren = getFocusableChildren(node);
        var focusedItemIndex = focusableChildren.indexOf(document.activeElement);

        // If the SHIFT key is being pressed while tabbing (moving backwards) and
        // the currently focused item is the first one, move the focus to the last
        // focusable item from the dialog element
        if (event.shiftKey && focusedItemIndex === 0) {
            focusableChildren[focusableChildren.length - 1].focus();
            event.preventDefault();
        }

        // If the SHIFT key is not being pressed (moving forwards) and the currently
        // focused item is the last one, move the focus to the first focusable item
        // from the dialog element
        else if (!event.shiftKey && focusedItemIndex === focusableChildren.length - 1) {
            focusableChildren[0].focus();
            event.preventDefault();
        }

    }


    /**
     * Private event handler used when listening to some specific key presses (namely ESCAPE and TAB).
     */
    function _bindKeypress(event) {

        // If the dialog is shown and the ESCAPE key is being pressed, prevent any
        // further effects from the ESCAPE key and hide the dialog
        if (isActive && event.which === ESCAPE_KEY) {
            event.preventDefault();
            deactivate();
        }

        // Get the active dialog node
        var node = $currentDialog && $currentDialog[0];
        if (!node) {
            return;
        }

        // If the dialog is shown and the TAB key is being pressed, make sure the
        // focus stays trapped within the dialog element
        if (isActive && event.which === TAB_KEY) {
            trapTabKey(node, event);
        }

    }


    /**
     * Private event handler used when making sure the focus stays within the currently open dialog.
     */
    function _maintainFocus(event) {

        var node = $currentDialog && $currentDialog[0];
        if (!node) {
            return;
        }

        // If the dialog is shown and the focus is not within the dialog element,
        // move it back to its first focusable child
        if (isActive && !node.contains(event.target)) {
            setFocusToFirstItem(node);
        }

    }


    // Public API
    // ---------------------------------------------------------

    app.modal = {
        open: open,
        close: close,
        showLoading: showLoading,
        hideLoading: hideLoading,
        contains: isInsideModal,
        isActive: function () {
            return isActive;
        }
    };

    eventEmitter = $(app.modal);

    app.modal.on = eventEmitter.on.bind(eventEmitter);
    app.modal.one = eventEmitter.one.bind(eventEmitter);
    app.modal.off = eventEmitter.off.bind(eventEmitter);

})(jQuery);
